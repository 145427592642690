import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby"

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import FrontPagedArt from "../../static/images/southwestern/southwestern-26.jpg";


const IndexPage = () => {
  return (
    <Layout displayNavigation={false}>
      <SEO title="Home" />
      <div className="front_page_container">
        <div className="front_page_image_wrapper">
          <img src={FrontPagedArt} alt="" />
        </div>

        <div className="front_page_text_container">
          <Typography component="h1" variant="h3" paragraph>
            Beverly Rafferty
          </Typography>
          <Typography component="h1" variant="h4" paragraph>
            Sacred Landscape Paintings
          </Typography>

          <Link to="/work/southwestern">
            <Button
              variant="outlined" 
              className="front_page_button"
              size="large" 
            >
              Enter
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  )
};

export default IndexPage;
